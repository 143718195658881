.page--resume {
  background: #040C0F;
  color: #fff;
}

.page--resume a:hover,
.page--resume a:focus {
  color: #D5EAF2;
}

.tile--resume {
  order: 2;
}

.tile--resume .tile_background {
  opacity: 0.5;
}
