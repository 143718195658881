.page--contact {
  background: #040C0F;
  color: #fff;
}

.tile--contact {
  order: 3;
}

::-webkit-input-placeholder {
  color: rgba(255,255,255, 0.5);
}

:-moz-placeholder {
  color: rgba(255,255,255, 0.5);
}

::-moz-placeholder {
  color: rgba(255,255,255, 0.5);
}

:-ms-input-placeholder {
  color: rgba(255,255,255, 0.5);
}

.form_status {
  display: block;
  margin: 0 0 20px;
}

.form_status--invalid {
  color: #ef4545;
}

.form_status--success {
  color: #37cd46;
}

.form_field {
  display: flex;
  margin: 0 0 15px;
  position: relative;
  width: 100%;
}

.form_field label {
  position: absolute;
  left: -999px;
}

.form_field input,
.form_field textarea {
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  padding: 10px;
  width: 100%;
}

input.button {
  display: inline-block;
  width: auto;
}
