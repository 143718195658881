.tile--home {
  align-items: flex-start;
  justify-content: flex-start;
  order: 0;
}

.tile--home .intro {
  font-size: 14px;
  line-height: 20px;
  max-width: 550px;
  z-index: 1;
}

.tile--home .tile_background {
  background-position: 100% 30%;
  transform: scale(1);
}

ul.social {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

ul.social li {
  margin: 0 10px 0 0;
}

ul.social li:last-child {
  margin: 0;
}

.social_link {
  display: block;
  font-size: 0;
  height: 25px;
  width: 25px;
  opacity: 0.5;
  position: relative;
  transition: all .3s;
}

.social_link:hover,
.social_link:focus {
  opacity: 1;
  transition: all .3s;
}

.social_link:before {
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social_link.facebook {
  background-image: url('../../images/facebook.svg');
}

.social_link.linkedin {
  background-image: url('../../images/linkedin.svg');
}

.social_link.github {
  background-image: url('../../images/git.svg');
}
