/* font-family: 'Open Sans', sans-serif; */
/* font-family: 'Source Sans Pro', sans-serif; */

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
}

input,
button,
textarea {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  margin: 0 0 20px;
}

h2 {
  font-size: 24px;
  margin: 0 0 20px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 10px;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  h3 {
    font-size: 16px;
  }
}

a {
  color: #54A1BF;
  font-weight: 600;
}

a:hover,
a:focus {
  color: #040C0F;
}

p {
  margin: 0 0 30px;
}

.button {
  border: 2px solid #fff;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  background: transparent;
  padding: 10px 15px;
  text-decoration: none;
}

.button:hover,
.button:focus {
  background: #fff;
  color: #040C0F;
  cursor: pointer;
}

.tile {
  color: #fff;
  box-sizing: border-box;
  background: #040C0F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh / 3);
  min-height: 200px;
  overflow: hidden;
  order: 5;
  padding: 20px;
  position: relative;
}


.tile:hover .tile_background,
.tile:focus .tile_background {
  transform: scale(1);
  transition: transform .3s;
}

.tile:hover .tile_background:before,
.tile:focus .tile_background:before {
  background: rgba(0,43,61, .9);
  transition: background .3s;
}

.tile:hover .tile_link,
.tile:focus .tile_link {
  transform: translateY(0);
  transition: transform .3s;
}

.tile_background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transform: scale(1.2);
  transition: transform .3s;
}

.tile_background:before {
  content: '';
  background: rgba(4,12,15, .8);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background .3s;
}

.tile_title {
  color: #D5EAF2;
  display: none;
  text-align: center;
  z-index: 1;
}

.tile_title--show {
  display: block;
}

.tile_logo {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 80%;
  max-height: 80px;
  z-index: 1;
}

.tile_logo img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.tile_logo.icon {
  margin: 0 0 10px;
  width: 50px;
}

@media only screen and (max-width: 600px) {
  .tile_logo.icon {
    width: 30px;
  }
}

.tile_link {
  color: #fff;
  font-size: 0;
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  transform: translateY(200%);
  transition: all .3s;
  opacity: 0.5;
}

.tile_link:hover,
.tile_link:focus {
  opacity: 1;
  transition: opacity .3s;
}

.tile_link:before {
  content: '';
  background-image: url('./images/external-link-symbol.svg');
  background-size: contain;
  height: 20px;
  width: 20px;
  position: absolute;
}

.tile_pageLink {
  color: #fff;
  display: block;
  font-size: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.page {
  padding: 0 20px;
  height: 100%;
  min-height: 100vh;
}

.page > div {
  max-width: 900px;
  margin: 0 auto;
  padding: 50px 0;
}

a.back {
  background: #fff;
  color: #040C0F;
  display: block;
  font-size: 0;
  position: fixed;
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}

a.back:before {
  content: '';
  background-image: url('./images/back.svg');
  background-size: contain;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
